import styled from "@emotion/styled"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core"
import Timeline from "@material-ui/lab/Timeline"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import * as React from "react"
import {useState} from "react"
import {Layout} from "../../components/Layout"
import {SectionContainer} from "../../components/Shared"
import {Page} from "../../components/PrivateRoutePage"
import {FaKickstarter} from "@react-icons/all-files/fa/FaKickstarter"
import {FaCogs} from "@react-icons/all-files/fa/FaCogs"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {BiCloudLightning} from "@react-icons/all-files/bi/BiCloudLightning"
import {GiDeliveryDrone} from "@react-icons/all-files/gi/GiDeliveryDrone"
import {BiChip} from "@react-icons/all-files/bi/BiChip"
import {GrManual} from "@react-icons/all-files/gr/GrManual"
import {FaShippingFast} from "@react-icons/all-files/fa/FaShippingFast"
import {GiSellCard} from "@react-icons/all-files/gi/GiSellCard"
import ksImage from "../../data/images/rgbdual/title-en.jpg"
import newFeaturesImage from "../../data/images/rgb-dual-timeline/80k.png"
import cartonsImage from "../../data/images/rgb-dual-timeline/cartons.jpg"
import papierBulleImage from "../../data/images/rgb-dual-timeline/papier-bulles.jpg"
import entretoisesImage from "../../data/images/rgb-dual-timeline/entretoises.jpg"
import gpioImage from "../../data/images/rgb-dual-timeline/gpio-extender.jpg"
import headersImage from "../../data/images/rgb-dual-timeline/headers.jpg"
import newPowerUpImage from "../../data/images/rgb-dual-timeline/newpowerup.png"
import rgbDualManualImage from "../../data/images/rgb-dual-timeline/rgbdual-manual.png"
import rgbDualFlyers from "../../data/images/rgb-dual-timeline/flyers.jpg"
import printing from "../../data/images/rgb-dual-timeline/printing.gif"
import filaments from "../../data/images/rgb-dual-timeline/filaments.jpg"
import ksEditionImage from "../../data/images/rgb-dual-timeline/600-ks-edition.jpg"
import lot1Image from "../../data/images/rgb-dual-timeline/600-lot1.jpg"
import uniqueImage from "../../data/images/rgb-dual-timeline/600-unique.jpg"
import packageAllBoards from "../../data/images/rgb-dual-timeline/package-1/all-boards.jpg"
import packageAllisHere from "../../data/images/rgb-dual-timeline/package-1/all-is-here.jpg"
import packagePackages from "../../data/images/rgb-dual-timeline/package-1/packages.jpg"
import packageBubbles from "../../data/images/rgb-dual-timeline/package-1/bubbles.jpg"
import packageManual from "../../data/images/rgb-dual-timeline/package-1/manual.jpg"


import {IoClose} from "@react-icons/all-files/io5/IoClose"
import {StaticImage} from "gatsby-plugin-image"
import {TextCenter} from "../diy/components/Shared"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {FiLayers} from "@react-icons/all-files/fi/FiLayers"
import {FaPrint} from "@react-icons/all-files/fa/FaPrint"
import {RGBDualPage} from "./RGBDualPageNext"
import {useBreadcrumb} from "../../hooks/useBreadcrumb";
import {FiPackage} from "@react-icons/all-files/fi/FiPackage";

const TimeLinePaper = styled(Paper)(({theme}) => ({
  padding: theme.spacings.md
}))

const todos = [
  {
    icon: <FaKickstarter size={25}/>,
    date: "07-01-2022",
    title: "Kickstarter end",
    content: "Kickstarter campaing ended",
    done: true,
    images: [ksImage]
  },
  {
    icon: <FaCogs size={25}/>,
    date: "21-01-2022",
    title: "Conception of new features",
    content: "New features as Wake up Button and Fan Control have been added to the board",
    done: true,
    images: [newFeaturesImage]
  },
  {
    icon: <GiDeliveryDrone size={25}/>,
    date: "25-01-2022",
    title: "Box and bubble wrap in stock",
    content: "We have everything to wrap your Recalbox RGB Dual!",
    done: true,
    images: [cartonsImage, papierBulleImage]
  },
  {
    icon: <GiDeliveryDrone size={25}/>,
    date: "28-01-2022",
    title: "Other parts received!",
    content: "GPIO extenders, headers, and more!",
    done: true,
    images: [entretoisesImage, headersImage, gpioImage]
  },
  {
    icon: <BiCloudLightning size={25}/>,
    date: "31-01-2022",
    title: "New power UP",
    content: "Because of a shortage of one of our components, we had to rebuild completly the Power Up module.",
    done: true,
    images: [newPowerUpImage]

  },
  {
    icon: <GrManual size={25}/>,
    date: "15-02-2022",
    title: "Recalbox RGB Dual manuals are in production.",
    content: "The design of the manual is over, and our provider is producing the 1500 items.",
    done: true,
    images: [rgbDualManualImage]
  },
  {
    icon: <FiLayers size={25}/>,
    date: "24-02-2022",
    title: "Received the *congratulations* flyers!",
    content: "We wanted you to see **thank you** when you open the box!",
    done: true,
    images: [rgbDualFlyers]
  },
  {
    icon: <FaPrint size={25}/>,
    date: "25-02-2022",
    title: "We have the filament!",
    content: "We started printing cases for your Recalbox RGB Dual!",
    done: true,
    images: [printing,filaments]
  },
  {
    icon: <BiChip size={25}/>,
    date: "25-02-2022",
    title: "The first 600 PCB are in stock",
    content: "First 600 in stock pcb details",
    done: true,
    images: [uniqueImage,lot1Image,ksEditionImage]
  },
  {
    icon: <FiPackage size={25}/>,
    date: "02-02-2022",
    title: "First 50 boards are ready to ship!",
    content: "First 50 boards are ready to ship! details",
    done: true,
    images: [packageAllBoards,packageAllisHere,packagePackages,packageBubbles,packageManual]
  },
  {
    icon: <FaShippingFast size={25}/>,
    date: "07-03-2022",
    title: "First packages are shipped!",
    content: "We will make a continuous shipping during Mars."
  },
  {
    icon: <BiChip size={25}/>,
    date: "20-03-2022",
    title: "The second batch of 600 PCB should be in stock",
    content: "The second batch will be sent during March/April."
  },
  {
    icon: <BiChip size={25}/>,
    date: "15-04-2022",
    title: "The third and final batch of PCB should be in stock",
    content: "The third batch will be sent during April/May."
  },
  {
    icon: <GiSellCard size={25}/>,
    date: "15-05-2022",
    title: "Retail stock",
    content: "We have shipped all Recalbox RGB Dual from kickstarter. We are planning to be distributed from different retailers."
  }
]

const TodoImage = styled.img({
  maxWidth: "10em",
  maxHeight: "10em",
  borderRadius: "1em",
  margin: "0.5em"
})

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const PhotoDialogTitle = (props: DialogTitleProps) => {
  const {children, onClose, ...other} = props

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton css={{
          position: "absolute",
          right: 8,
          top: 8,
        }} onClick={onClose}>
          <IoClose/>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const ResponsiveTimeLineItem = styled(TimelineItem)(({theme}) => ({
  [`@media (max-width: ${theme!.breakpoints.values.sm}px)`]: {
    flexDirection: "column!important" as any
  }
}))

const RRGBDRoadmap = () => {
  const [state, setState] = useState({picture: undefined as any, title: ""})
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(RRGBDRoadmapPage)
  const handleClickOpen = (picture: any, title: string) => {
    setState({picture, title})
  }
  const handleClose = () => {
    setState({picture: undefined, title: ""})
  }

  return (
    <Layout title={"Recalbox RGB Dual Roadmap"}>
      <SectionContainer>
        {breadCrumb}
        <TextCenter>
          <StaticImage css={{maxWidth: "300px", objectFit: "cover"}}
                       src={"../../data/images/rgb-dual-timeline/logo.png"} alt={"Logo"}/>
          <TransMdTypo variant={"h1"} css={{margin: "1em"}}>
            Recalbox RGB Dual Roadmap
          </TransMdTypo>
        </TextCenter>
        <Dialog
          onClose={handleClose}
          open={state.picture}
        >
          <PhotoDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {state.title}
          </PhotoDialogTitle>
          <DialogContent dividers>
            <img css={{width: "100%"}} src={state.picture}/>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Timeline align="alternate">
          {todos.map((todo) => (
            <ResponsiveTimeLineItem>
              <TimelineSeparator>
                <TimelineDot color={todo.done ? "secondary" : "inherit"}>{todo.icon}</TimelineDot>
                <TimelineConnector/>
              </TimelineSeparator>
              <TimelineContent>
                <TimeLinePaper elevation={3}>
                  <TransMdTypo color={"textSecondary"} variant="h6">
                    {todo.date}
                  </TransMdTypo>
                  <TransMdTypo variant="h5" component="h2">
                    {t(`rrgbdroadmap^${todo.title}`)}
                  </TransMdTypo>
                  <TransMdTypo>{t(`rrgbdroadmap^${todo.content}`)}</TransMdTypo>
                  {todo.images?.map(image => {
                      return <Button onClick={() => handleClickOpen(image, t(`rrgbdroadmap^${todo.title}`))}><TodoImage
                        src={image}/></Button>
                    }
                  )}
                </TimeLinePaper>
              </TimelineContent>
            </ResponsiveTimeLineItem>
          ))}
        </Timeline>
      </SectionContainer>
    </Layout>
  )
}

const getUrl = () => "/recalbox-rgb-dual/roadmap"
export const RRGBDRoadmapPage: Page = {Page: RRGBDRoadmap, getUrl,
  parentPage: () => RGBDualPage, breadCrumb: {name: "Roadmap", link: getUrl() }}
